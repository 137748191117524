import { API_ENDPOINTS } from './api-endpoints';
import { setCsrfForFetch, setCsrfForXHR } from './auth/csrf-interceptors';
import { installConsentScript } from './consent';
import { renderApp } from './render';
import type { AppConfig, RuntimeConfig } from './types';
import { initFetchJSON } from './utils/fetch-json';
import { initTechLogger } from './utils/logging';

export async function setupApp(appConfig: AppConfig): Promise<void> {
  const config: RuntimeConfig = {
    ...appConfig,
    proxy: API_ENDPOINTS.proxy,
    endpoints: API_ENDPOINTS,
  };

  setCsrfForFetch(config.auth.csrfCookieName, config.auth.csrfHeaderName);
  setCsrfForXHR(config.auth.csrfCookieName, config.auth.csrfHeaderName);

  initFetchJSON(config);
  initTechLogger(config);
  document.title = config.title;

  installConsentScript(config.oneTrust.id);

  // The following code is only reached initially if user is authenticated (the page will be refreshed otherwise)
  renderApp(config);
}

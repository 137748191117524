import type { ClientSession, User } from '@arnold/common/types';
import { msalInstance } from '@basic-care/basic-care-common';

import type { RuntimeConfig } from '../types';
import { fetchJSON } from '../utils/fetch-json';
import { getSessionStorageItem, StorageKey } from '../utils/storage';
import { ACTIONS_TO_ACTION_GROUPS_MAP } from './constants';
import { addSessionData, removeSessionData } from './session.provider';
import type { ActionGroupsSelector } from './types';

export function getUserData(): User | undefined {
  //  return getSessionDataFromStorage()?.user;
  return getUserDetails() || undefined;
}

export function getUserDetails(): User | null {
  const accounts = msalInstance?.getAllAccounts();
  if (!accounts || accounts.length === 0) {
    return null; // Return null if no accounts are found
  }

  const userData: any = accounts[0]?.idTokenClaims?.['ZeissIdBase'];
  if (!userData) {
    return null; // Return null if userData is not found
  }

  try {
    const parsedUserData = JSON.parse(userData);
    return {
      firstName: parsedUserData.firstName ?? '',
      lastName: parsedUserData.lastName ?? '',
      eMail: '',
      accountId: 0,
      dateOfBirth: '',
      department: '',
      identityProvider: 0,
      jobTitle: '',
      language: '',
      lastChange: '',
      salutation: '',
    };
  } catch (error) {
    console.error('Error parsing user data:', error);
    return null; // Return null if parsing fails
  }
}

export function getSessionData(): ClientSession | undefined {
  return getSessionDataFromStorage();
}

export function fetchAndStoreSessionData(config: RuntimeConfig): Promise<ClientSession | void> {
  return fetchJSON<ClientSession>(config.endpoints.auth.sessionInfo)
    .then((userData) => {
      addSessionData(userData);
      return userData;
    })
    .catch(() => {
      removeSessionData();
    });
}

export function getAcceptedTermsAndConditions(
  termsAndConditionsUrl: string,
  language: string
): Promise<string> {
  const languageQueryParam = new URLSearchParams({ language }).toString();
  const url = `${termsAndConditionsUrl}/?${languageQueryParam}`;

  return fetch(url).then((res) => res.text());
}

function getSessionDataFromStorage(): ClientSession | undefined {
  const rawData = getSessionStorageItem(StorageKey.SESSION_DATA) || '';
  try {
    return JSON.parse(rawData);
  } catch (error) {
    return undefined;
  }
}

export function hasUserAccessTo(actionGroupsSelector: ActionGroupsSelector): boolean {
  const actionGroups = actionGroupsSelector(ACTIONS_TO_ACTION_GROUPS_MAP);
  const userActionGroups = getSessionData()?.actionGroups || [];

  return actionGroups.some((actionGroup) => userActionGroups.includes(actionGroup));
}
